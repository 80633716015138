import React from 'react'
import { graphql, Link } from 'gatsby'
import styled from "@emotion/styled"
import loadable from '@loadable/component'
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { INLINES } from '@contentful/rich-text-types'

import Seo from '../components/seo'

const Footer = loadable(() => import('../components/footer'))
const Layout = loadable(() => import('../components/layout'))
const Content = loadable(() => import('../components/content'))
const Team = loadable(() => import('../components/team'))
const Title = loadable(() => import('../components/title'))
const Projectlist = loadable(() => import('../components/projectlist'))
const Newslist = loadable(() => import('../components/newslist'))
const Contactside = loadable(() => import('../components/contactside'))
const Awardlist = loadable(() => import('../components/awardlist'))
const Presslist = loadable(() => import('../components/presslist'))

const Textblock = styled.div`
background-color: #fff;
padding: 18px 5vw 0 0;
h1 {
  line-height: 1.15em;
  font-size: 1.0625rem;
  font-weight: 800;
  letter-spacing: .75px;
  height: 8vh;
}

b {
    font-size: .9125rem;
    letter-spacing: 1.5px;
    font-weight: 800;
}
img {
    margin-top: 7.5vh !important;
}
`
const Sidebar = styled.div`
padding-top: 18px;
font-size: .9125rem;
color: rgba(0,0,0,0.75);
width: 90%;
h1 {
  height: 7vh;
}
@media (max-width: 599px) {
  margin-top: 2.5vh;
  h1 {
    height: 6vh;
  }
}
p {
  line-height: 1.5em;
}
b {
  font-size: .8125rem;
  letter-spacing: 1.5px;
  font-weight: 800;
}
`
const Listwrap = styled.div`
h2 {
    font-weight: 800;
    margin-bottom: 2.5vh;
    padding-top: 2.5vh;
    font-size: .875rem;
    color: rgb(0,0,0);
}
h3 {
    border-top: solid 1px rgba(0,0,0,0.1);
    padding-bottom: 1.5vh;
    padding-top: 2vh;
}
ul {
    margin-bottom: 4vh;
}
li {
    font-size: .875rem;
    line-height: 1.5em;
    font-weight: 500;
    letter-spacing: .5px;
    padding-bottom: .75em;
    @media (max-width: 599px) {
      padding-bottom: 1em;
    }
}
p {
  padding-bottom: 0;
  line-height: 1.33em;
  display: inline;
}
`
const options = {
    renderNode: {
      [INLINES.ENTRY_HYPERLINK]: ({
        data: {
          target: { slug, title },
        },
      }) => <Link to={`/${slug}`}>{title}</Link>,
      // [BLOCKS.EMBEDDED_ASSET]: node => {
      //   const image = getImage(node.data.target)
      //   return <GatsbyImage image={image} />
      //   },
    },
    renderText:
      text => {
        return text.split('\n').reduce((children, textSegment, index) => {
          return [...children, index > 0 && <br key={index} />, textSegment];
        }, []);
      },
  }

const Page = ({ data }) => {
    const pageTitle = data.page.title
    const { text } = data.page
    return (
        <>
        <Layout>
          <Seo title={data.page.title}/>
        {pageTitle === 'About' && 
        <>
        <Content>
            <Textblock>
            {renderRichText(text, options)}
            </Textblock>
            <Sidebar>{renderRichText(data.page.sidebar, options)}</Sidebar>
        </Content>
        <Team />
        </>
        }
        {pageTitle === 'Work' && 
        <Projectlist />
        }
        {pageTitle === 'News' && 
        <Content>
        <Newslist />
        <Sidebar>
          <Title>Awards</Title>
          <Listwrap>
            <Awardlist />
          </Listwrap>
          <Title>Press</Title>
          <Listwrap>
            <Presslist />
          </Listwrap>
          </Sidebar>
        </Content>
        }
        {pageTitle === 'Contact' &&
        <Content>
            { text && 
            <Textblock>
            <Title>{pageTitle}</Title>
            {renderRichText(text, options)}
            </Textblock>
            }
            <Sidebar>
            <Contactside />
            </Sidebar>
        </Content>
        }
        </Layout>
        <Footer />
        </>
    )
}

export const query = graphql`
query ($slug: String) {
    page: contentfulPage(slug: {eq: $slug}) {
      id
      title
      slug
      text {
        raw
        references {
          ... on ContentfulPage {
            contentful_id
            title
            slug
          }
        }
      }
      sidebar {
        raw
      }
    }
  }
`

export default Page